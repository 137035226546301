import { useState, useEffect } from "react";

import { generateClient } from "aws-amplify/api";
import { queryStopTimesByStopIdIndex } from "../../graphql/queries";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { BeforeAndAfter } from "./BeforeAndAfter";
import { FilteredStopTimes } from "./FilteredStopTimes";
import { Intervals } from "./Intervals";
import { getStopTimesAnchorIndex } from "../Common/CommonFunc";
// import { HourlyAverage } from "./HourlyAverage";

export function StopTimes(props) {
  const client = generateClient();
  const page = props.page;

  const [stopTimes, setStopTimes] = useState();

  async function fetchData(stopId) {
    // stop_times
    const tmpArr = [];
    const sts = await client.graphql({
      query: queryStopTimesByStopIdIndex,
      variables: { stop_id: stopId },
    });
    const { items: datas, nextToken } = sts.data.queryStopTimesByStopIdIndex;
    tmpArr.push(...datas);
    let next = nextToken;
    while (next) {
      let stsNxt = await client.graphql({
        query: queryStopTimesByStopIdIndex,
        variables: { stop_id: stopId, after: next },
      });
      const { items: datas2, nextToken } =
        stsNxt.data.queryStopTimesByStopIdIndex;
      tmpArr.push(...datas2);
      next = nextToken;
    }
    tmpArr.sort(function (a, b) {
      if (a.departure_time > b.departure_time) {
        return 1;
      } else {
        return -1;
      }
    });
    const stsArr = tmpArr.filter((elem) => elem.pickup_type !== 1);
    setStopTimes(stsArr);
  }

  let idx = null;
  if (stopTimes) {
    idx = typeof page === "number" ? page : getStopTimesAnchorIndex(stopTimes);
  }

  useEffect(() => {
    fetchData(props.stop.stop_id);
  }, []);

  return (
    <>
      {!stopTimes && <CircularProgress sx={{ m: 1 }} />}
      {stopTimes?.length === 0 && (
        <Typography sx={{ m: 2 }}>本日は運行便がありません😪</Typography>
      )}
      {stopTimes && stopTimes.length !== 0 && (
        <>
          <FilteredStopTimes stopTimes={stopTimes} page={page} />
          <BeforeAndAfter idx={idx} len={stopTimes.length} num={5} />
          <Intervals stopTimes={stopTimes} />
          {/* <HourlyAverage stopTimes={stopTimes} /> */}
        </>
      )}
    </>
  );
}
