import { record } from "aws-amplify/analytics";

import { CommonFooter } from "../Common/CommonFooter";
import Typography from "@mui/material/Typography";

export default function Terms() {
  // title
  const title = "利用規約 - t2.mame2.link";
  document.title = title;

  // description
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", title);

  // aws-amplify/analitycs
  record({
    name: "termsVisit",
  });

  return (
    <>
      <main>
        <Typography component="h1" variant="h5" sx={{ m: 2 }}>
          利用規約
        </Typography>
        <Typography sx={{ m: 2 }}>最終更新日: 2023年11月1日</Typography>
        <Typography sx={{ m: 2 }}>
          このサービス（以下「本サービス」）をご利用いただきありがとうございます。
          本サービスを利用することにより、本規約に同意することになります。以下を注意してお読みください。
          本サービスを不正に利用しないでください。本サービスで提供している情報、
          リンク先などによりいかなる損失や損害などの被害が発生しても、本サービスでは責任を負いません。
        </Typography>
      </main>
      <CommonFooter></CommonFooter>
    </>
  );
}
